// AppModel.js
import { atom, selector } from 'recoil';
import flagEnglish from '../asset/flag_english.svg';
import flagKorean from '../asset/flag_korean.svg';
import flagSpanish from '../asset/flag_spanish.svg';
import SvgBenefitSaveDebug from '../asset/ben_save_debug.svg';
import SvgBenefitSaveTime from '../asset/ben_save_time.svg';
import SvgBenefitTeamwork from '../asset/ben_teamwork.svg';
import SvgBenefitPattern from '../asset/ben_pattern.svg';

export const UserLanguage = {
    english:{code:'en-US', index:0, label:'English', src: flagEnglish, key:'english'},
    korean:{code:'ko-KR', index:1, label:'한국어', src: flagKorean, key:'korean'},
    spanish:{code:'es-ES', index:2, label:'Español', src: flagSpanish, key:'spanish'},
};
const languageAtom = atom({default:{key:UserLanguage.english.key, label:UserLanguage.english.label}, key:'language'});
export {languageAtom};

export const HomeText = {
    VzmAims: {
        title: {
            // Our aim
            korean: "Version Zero Maker의 지향점",
            english: "Vision of Version Zero Maker",
            spanish: "Visión de Version Zero Maker"
        },
        aims: {
            practicality: {
                key: 'practicality',
                title: {
                    korean: "실용적일 것",
                    english: "Being practical",
                    spanish: "Ser práctico"
                },
                say: {
                    korean: <div>
                        <p>데이터베이스 스키마 정보를 프로그래머의 디자인 의도가 담긴 템플릿에 적용하여<br/>
                            신속하게 초기 소스코드를 생성하여 시간을 절약하는 것이<br/>
                            이 서비스의 가장 중요한 목표입니다.
                        </p>
                    </div>,
                    english: <div>
                        <p>It is the most important goal of this service to save time<br/>
                            by quickly generating initial source codes
                            by applying database schema information<br/>
                            to templates containing the programmer's design intent.
                        </p>
                    </div>,
                    spanish: <div>
                        <p>Es el objetivo más importante de este servicio ahorrar tiempo<br/>
                            generando rápidamente códigos fuente iniciales
                            aplicando información del esquema de la base de datos<br/>
                            a plantillas que contienen la intención de diseño del programador.
                        </p>
                    </div>
                },
                position: {top: 170, left: 220}
            },
            consistency: {
                key: 'consistency',
                title: {
                    korean: "일관성을 부여하는 것",
                    english: "Giving Consistency",
                    spanish: "Dar Consistencia"
                },
                say: {
                    korean: <div>
                        <p>Version Zero Maker는 프로젝트가 진행되는 동안 템플릿을 사용함으로써<br/>
                            코드가 프로그래머의 일시적인 생각에 따라<br/>
                            디자인 패턴에서 벗어나는 것을 막아줍니다.
                        </p>
                    </div>,
                    english: <div>
                        <p>Using templates keeps you maintain design patterns<br/>
                            throughout the project.<br/>
                            You would consider between your temporary thoughts and design patterns.
                        </p>
                    </div>,
                    spanish: <div>
                        <p>El uso de plantillas le ayuda a mantener patrones de diseño<br/>
                            a lo largo del proyecto.<br/>
                            Consideraría entre sus pensamientos temporales y patrones de diseño.
                        </p>
                    </div>
                },
                position: {top: 120, left: 320},
            },
            teamwork: {
                key: 'teamwork',
                title: {
                    korean: "팀워크에 도움될 것",
                    english: "Teamwork",
                    spanish: "Trabajo en equipo"
                },
                say: {
                    korean: <div>
                        <p>프로젝트에 참여하는 모든 개발자들이 동일한 템플릿을 사용하고,<br/>
                            데이터베이스 스키마 정보에 지속적으로 노출되어 있으면<br/>
                            팀원들 모두가 서로의 코드를 이해하고 협업하는 데 도움이 됩니다.
                        </p>
                    </div>,
                    english: <div>
                        <p>If all developers participating in the project use the same template<br/>
                            and are continuously exposed to database schema information,<br/>
                            it helps all team members understand each other's code and collaborate.
                        </p>
                    </div>,
                    spanish: <div>
                        <p>Si todos los desarrolladores que participan en el proyecto utilizan la misma plantilla<br/>
                            y están continuamente expuestos a la información del esquema de la base de datos,<br/>
                            ayuda a todos los miembros del equipo a entender el código de los demás y colaborar.
                        </p>
                    </div>
                },
                position: {top: 215, left: 220}
            }
        }
    },
    whatDoesVzmDo: {
        title: {
            korean: "Version Zero Maker를 사용하여 무엇을 할 수 있나요?",
            english: "What can you do with Version Zero Maker?",
            spanish: "¿Qué puedes hacer con Version Zero Maker?"
        },
        say: {
            korean: <div>
                <ul>
                    <li>Version Zero Maker는 사용자의 데이터베이스 스키마를 템플릿에 적용하여 초기 버전의 소스코드를 생성합니다.</li>
                    <li>시스템 아키텍쳐에 따라 각 레이어별로 설계 패턴을 확립하고 템플릿을 만들어 주세요. 코드에 일관성이 유지됩니다.</li>
                    <li>동일한 프로젝트를 여러 사용자가 공유하며 협업할 수 있습니다. 자연스럽게 데이터베이스와 시스템설계 정보의 공유가 이루어집니다.</li>
                </ul>
            </div>,
            english: <div>
                <ul>
                    <li>Version Zero Maker generates the initial version of source codes by applying your database schema to templates.</li>
                    <li>Establish design patterns for each layer according to the system architecture and create templates. The code remains consistent.</li>
                    <li>Multiple users can share and collaborate on the same project. Naturally, information on database and system design is shared.</li>
                </ul>
            </div>,
            spanish: <div>
                <ul>
                    <li>Version Zero Maker genera la versión inicial de los códigos fuente aplicando su esquema de base de datos a plantillas.</li>
                    <li>Establezca patrones de diseño para cada capa según la arquitectura del sistema y cree plantillas. El código permanece consistente.</li>
                    <li>Varios usuarios pueden compartir y colaborar en el mismo proyecto. Naturalmente, se comparte información sobre la base de datos y el diseño del sistema.</li>
                </ul>
            </div>
        }
    },
    whatDoYouGet: {
        title: {
            korean: "Version Zero Maker를 사용하여 무엇을 얻을 수 있나요?",
            english: "What can you get with Version Zero Maker?",
            spanish: "¿Qué puedes obtener con Version Zero Maker?"
        },
        lines: [
            {
                say: {
                    korean: <div>
                        <p><b>시간을 절약합니다.</b></p>
                        <ul>
                            <li>대부분의 코드는 전형적인 디자인 패턴과 전형적인 클래스, 함수, 변수 이름, 타입명 등을 따릅니다.</li>
                            <li>템플릿과 데이터베이스 스키마를 사용하고 이러한 특성을 사용하여 기본적인 코드를 생성하면
                                초기 소스코드 작성 시간을 현저하게 줄일 수 있습니다.
                            </li>
                            <li>
                                만일 동일한 템플릿을 여러 프로젝트에 사용한다면, 절약할 수 있는 시간은 더욱 커집니다.
                            </li>
                        </ul>
                    </div>,
                    english: <div>
                        <p><b>Save time</b></p>
                        <ul>
                            <li>Most codes follow typical design patterns and typical class, function, variable names, type names, etc.</li>
                            <li>By using templates with database schema information and generating basic codes using these features,
                                you can significantly reduce the time to write initial source codes.
                            </li>
                            <li>
                                If you use the same template for multiple projects, you can save even more time.
                            </li>
                        </ul>
                    </div>,
                    spanish: <div>
                        <p><b>Ahorra tiempo</b></p>
                        <ul>
                            <li>La mayoría de los códigos siguen patrones de diseño típicos y nombres típicos de clases, funciones, variables, nombres de tipos, etc.</li>
                            <li>Al utilizar plantillas con información del esquema de la base de datos y generar códigos básicos utilizando estas características,
                                puede reducir significativamente el tiempo para escribir códigos fuente iniciales.
                            </li>
                            <li>
                                Si utiliza la misma plantilla para varios proyectos, puede ahorrar aún más tiempo.
                            </li>
                        </ul>
                    </div>
                },
                image: SvgBenefitSaveTime
            },
            {
                say: {
                    korean: <div>
                        <p><b>디버깅 시간을 줄입니다.</b></p>
                        <ul>
                            <li>전형적인 기본 코드를 손으로 입력하면서 단순한 오타로 인한 반복적인 백스페이스 누르기,
                                혹은 비슷한 이름을 잘못입력하여 결국 버그로 남게되는 것 등은
                                프로그래머라면 모두가 일상적으로 겪는 일입니다.
                            </li>
                            <li>템플릿을 사용하여 작성된 코드는 오타 발생을 원천적으로 차단합니다.</li>
                            <li>적어도 이렇게 생성한 초기코드에 대해서만큼은 오타로 인한 디버깅 시간을 크게 줄여줄 것입니다.</li>
                        </ul>
                    </div>,
                    english: <div>
                        <p><b>Reduce debugging time</b></p>
                        <ul>
                            <li>Manually entering typical basic codes and repetitive backspacing due to simple typos,
                                or ultimately leaving bugs due to incorrectly entering similar names are
                                something that all programmers experience on a daily basis.
                            </li>
                            <li>Codes written using templates prevent typos at the source.</li>
                            <li>At least for the initial codes generated in this way, it will greatly reduce the debugging time due to typos.</li>
                        </ul>
                    </div>,
                    spanish: <div>
                        <p><b>Reduce el tiempo de depuración</b></p>
                        <ul>
                            <li>Ingresar manualmente códigos básicos típicos y retroceder repetidamente debido a simples errores tipográficos,
                                o finalmente dejar errores debido a ingresar nombres similares incorrectamente son
                                algo que todos los programadores experimentan a diario.
                            </li>
                            <li>Los códigos escritos utilizando plantillas previenen errores tipográficos en la fuente.</li>
                            <li>Al menos para los códigos iniciales generados de esta manera, reducirá en gran medida el tiempo de depuración debido a errores tipográficos.</li>
                        </ul>
                    </div>
                },
                image: SvgBenefitSaveDebug
            },
            {
                say: {
                    korean: <div>
                        <p><b>코드의 일관성 유지</b></p>
                        <ul>
                            <li>템플릿을 사용하여 작성된 코드는 프로젝트 전체에서 일관성을 유지합니다.</li>
                            <li>프로젝트가 진행되는 동안 템플릿을 사용하면 코드가 프로그래머의 일시적인 생각에 따라<br/>
                                디자인 패턴에서 벗어나는 것을 막아줍니다.
                            </li>
                        </ul>
                    </div>,
                    english: <div>
                        <p><b>Maintain code consistency</b></p>
                        <ul>
                            <li>Codes written using templates maintain consistency throughout the project.</li>
                            <li>Using templates during the project prevents codes from deviating from design patterns<br/>
                                according to the programmer's temporary thoughts.
                            </li>
                        </ul>
                    </div>,
                    spanish: <div>
                        <p><b>Mantener la consistencia del código</b></p>
                        <ul>
                            <li>Los códigos escritos utilizando plantillas mantienen la consistencia en todo el proyecto.</li>
                            <li>Utilizar plantillas durante el proyecto evita que los códigos se desvíen de los patrones de diseño<br/>
                                según los pensamientos temporales del programador.
                            </li>
                        </ul>
                    </div>
                },
                image: SvgBenefitPattern
            },
            {
                say: {
                    korean: <div>
                        <p><b>팀워크 향상</b></p>
                        <ul>
                            <li>동일한 디자인 패턴을 유지하는 것, 다른 분야의 프로그래머가 사용하는 디자인 패턴을 이해하는 것은
                                팀원간 소통에 있어 매우 중요한 요소입니다.</li>
                            <li>데이터베이스 스키머 정보가 지속적으로 입력되는 것 역시 팀원들 사이의 소통이 매끄럽게 되도록 도움을 줍니다.</li>
                            <li>서로에 대해 아는 것, 시스템에 대해 이해가 깊은 것만큼 개발 프로젝트에서 중요한 것이 얼마나 될까요?</li>
                        </ul>
                    </div>,
                    english: <div>
                        <p><b>Enhance teamwork</b></p>
                        <ul>
                            <li>Maintaining the same design pattern and understanding the design pattern used by programmers in other fields
                                are very important factors in team communication.</li>
                            <li>Continuous input of database schema information also helps smooth communication among team members.</li>
                            <li>You can hardly imagine other things as important as knowing each other
                                and having a deep understanding of the system in a development project.</li>
                        </ul>
                    </div>,
                    spanish: <div>
                        <p><b>Mejorar el trabajo en equipo</b></p>
                        <ul>
                            <li>Mantener el mismo patrón de diseño y comprender el patrón de diseño utilizado por programadores en otros campos
                                son factores muy importantes en la comunicación del equipo.</li>
                            <li>La entrada continua de información del esquema de la base de datos también ayuda a una comunicación fluida entre los miembros del equipo.</li>
                            <li>Difícilmente puedes imaginar otras cosas tan importantes como conocerse
                                y tener un profundo conocimiento del sistema en un proyecto de desarrollo.</li>
                        </ul>
                    </div>
                },
                image: SvgBenefitTeamwork
            }
        ]
    },
    whatDoIDo: {
        title: {
            korean: "서비스를 이용하려면 어떻게 하나요?",
            english: "How do I use the service?",
            spanish: "¿Cómo uso el servicio?"
        },
        say: {
            korean: <div>
                <ul>
                    <li>Version Zero Maker(VZM)는 데이터베이스 스키마 정보에 접근할 수 있어야 합니다.</li>
                    <li>데이터베이스에 접근하는 두 가지 방법 중 하나는 RDA(Relational Database Adapter)를 통하는 것(왼쪽 그림)이고,
                        다른 하나는 VZM에 내장되어 있는 JDBC를 통하는 것(오른쪽 그림)입니다.
                    </li>
                    <li>RDA를 사용하려면 먼저 RDA를 다운로드 받거나 RDA 도커를 받아 설치합니다.</li>
                    <li>VZM에서 프로젝트를 생성한 후 RDA 정보를 입력하거나 JDBC에 제공할 데이터베이스 접근 정보를 입력합니다.</li>
                    <li>혼자 일하는 것이 아니라면 동료들을 초대합니다.</li>
                    <li>레이어별로 필요한 템플릿들을 작성합니다.</li>
                    <li>템플릿을 각 테이블에 적용해서 소스코드를 생성합니다.</li>
                    <li>서비스 가입에 필요한 유일한 조건은 구글 계정입니다.</li>
                    <li>자세한 내용은 <a href="/doc/userguide/index.html?lang=korean">사용 안내</a>와 <a href="/doc/vzmref/index.html?lang=korean">템플릿 설명서</a>를 참조하세요.</li>
                </ul>
            </div>,
            english: <div>
                <ul>
                    <li>Version Zero Maker (VZM) must be able to access database schema information.</li>
                    <li>One of the two ways to access the database is through RDA (Relational Database Adapter) (left image),
                        and the other is through JDBC embedded in VZM (right image).
                    </li>
                    <li>To use RDA, download RDA or install RDA Docker first.</li>
                    <li>After creating a project in VZM, enter RDA information or enter database access information to be provided to JDBC.</li>
                    <li>If you are not working alone, invite your colleagues.</li>
                    <li>Write the templates needed for each layer.</li>
                    <li>Apply the templates to each table to generate source codes.</li>
                    <li>The only requirement for signing up for the service is to have a Google account.</li>
                    <li>For more information, refer to the <a href="/doc/userguide/index.html?lang=korean">user guide</a> and <a href="/doc/vzmref/index.html">reference</a> document.</li>
                </ul>
            </div>,
            spanish: <div>
                <ul>
                    <li>Version Zero Maker (VZM) debe poder acceder a la información del esquema de la base de datos.</li>
                    <li>Una de las dos formas de acceder a la base de datos es a través de RDA (Adaptador de Base de Datos Relacional) (imagen izquierda),
                        y la otra es a través de JDBC incrustado en VZM (imagen derecha).
                    </li>
                    <li>Para usar RDA, primero descargue RDA o instale RDA Docker.</li>
                    <li>Después de crear un proyecto en VZM, ingrese la información de RDA o ingrese la información de acceso a la base de datos que se proporcionará a JDBC.</li>
                    <li>Si no está trabajando solo, invite a sus colegas.</li>
                    <li>Escriba las plantillas necesarias para cada capa.</li>
                    <li>Aplique las plantillas a cada tabla para generar códigos fuente.</li>
                    <li>El único requisito para registrarse en el servicio es tener una cuenta de Google.</li>
                    <li>Para obtener más información, consulte <a href="/doc/userguide/index.html?lang=spanish">la guía del usuario</a> y <a href="/doc/vzmref/index.html?lang=spanish">el documento de referencia</a>.</li>
                </ul>
            </div>
        }
    }
};