// TopRightBox.js
import React, { useEffect, useRef, useState } from 'react';
import { Box, Grow, Link,  } from '@mui/material';
import { styled } from '@mui/material/styles';
import { usePolyglot } from '../../api/vzm-hook';
import SvgTopRightWave from '../../asset/top_right_wave.svg';

const SideLen = 90;
const ViewContainer = styled(Box)({position:'fixed', top:0, right:0, width:SideLen, height:SideLen,
    display:'flex', flexDirection:'column', alignItems:'end', justifyContent:'start', padding:5,
    textAlign:'right', color:'#fff', fontSize:'0.8rem',
    // text height
    lineHeight:'1.2rem',
});


export default function TopRightBox({
    open
}) {
    const polyglot = usePolyglot();
    return (
        <Grow in={open}>
            <ViewContainer>
                <Link href="/vzm" style={{color:'yellow'}}>
                    <img src={SvgTopRightWave} alt="VZM" width={SideLen} height={SideLen} style={{position:'absolute', top:0, right:0, opacity:0.3}} />
                    {polyglot({
                        korean: <div>VZM<br/>서비스<br/>시작</div>,
                        english: <div>Try<br/>VZM<br/>Service</div>,
                        spanish: <div>Prueba<br/>el servicio<br/>VZM</div>,
                    })}
                </Link>
            </ViewContainer>
        </Grow>
    );
}