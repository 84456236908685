// VzmConcept.js
import React, { useEffect, useRef, useState } from 'react';
import { Box, Grow, Zoom  } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useInterval } from 'react-use';
import SvgVzmConcept from '../../asset/vzm_concept.svg'; // original 1438 * 691.

/*
Position of Table View in SVG: (x, y, w, h)
    Paper: 0, 201, 192, 160
Positions in original SVG: (x, y, w, h)
    Top-1:  504, 121, 160, 88
    Top-2:  744, 121, 160, 88
    Top-3:  984, 121, 160, 88
    Bottom-1: 384, 425, 160, 88
    Bottom-2: 624, 425, 160, 88
    Bottom-3: 864, 425, 160, 88
    Bottom-4: 1104, 425, 160, 88
Presentation Ratio: 800 / 1438 = 0.556
*/

const OriginalWidth = 1438;
const ContainerWidth = 800;
const Ratio = ContainerWidth / OriginalWidth;

function makeBoxSizeObject(x, y, w, h) {
    return {
        x: (x+8) * Ratio,
        y: y * Ratio,
        w: w * Ratio,
        h: h * Ratio,
    };
}

const ConceptBox = styled(Box)({
    width: ContainerWidth,
    position: 'relative',
});

const TablePaperWindow = makeBoxSizeObject(0, 201, 192, 160);

const TemplatePaperWindows = [
    {window:makeBoxSizeObject(504, 121, 160, 88), delay: 80, color: '#fff'},
    {window:makeBoxSizeObject(744, 121, 160, 88), delay: 100, color: '#ffc'},
    {window:makeBoxSizeObject(984, 121, 160, 88), delay: 400, color: '#cdf'},
    {window:makeBoxSizeObject(384, 425, 160, 88), delay: 120, color: '#fcd'},
    {window:makeBoxSizeObject(624, 425, 160, 88), delay: 350, color: '#cfd'},
    {window:makeBoxSizeObject(864, 425, 160, 88), delay: 180, color: '#dfc'},
    {window:makeBoxSizeObject(1104, 425, 160, 88), delay: 260, color: '#cfc'},
];

const TableView = styled(Box)({
    position: 'absolute',
    display: 'flex', alignItems: 'center', justifyContent: 'center',
    color: '#000040',
    fontSize: '9pt', fontWeight: 'bold', textShadow: '1px 1px 3px #fff',
});

const TextPaper = styled(Box)({
    position: 'absolute',
    display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden',
    fontSize: '10pt', fontWeight: 'bold',
});

const TableBox = ({children, window}) => {
    const { x, y, w, h } = window;
    return (
        <TableView sx={{ left: x, top: y, width: w, height: h }}>
            {children}
        </TableView>
    );
};


function tableNameToText(name) {
    // TABLE_NAME -> Table Name
    return name.split('_').map((item)=>item.charAt(0) + item.slice(1).toLowerCase()).join(' ');
}

const Tables = ['PRODUCT', 'CUSTOMER', 'ORDER', 'ORDER_ITEM', 'USER_INFO', 'USER_ROLE'];
const TableWords = Tables.map((item)=>tableNameToText(item));

const LoopCount = 5;

/**
 * Show the concept of VZM.
 * Set SVG image as background.
 * Animate Table name in Table positions.
 */
export default function VzmConceptMove() {
    const [showNow, setShowNow] = useState(false);
    const [tableName, setTableName] = useState(null);
    const [tableWord, setTableWord] = useState(null);
    const indexRef = useRef(0);
    const blinkRef = useRef(0);

    useInterval(()=>{
        const rest = blinkRef.current % LoopCount;
        if(rest === 0) {
            const table = Tables[indexRef.current];
            setShowNow(true);
            setTableName(table);
            setTableWord(TableWords[indexRef.current]);
            indexRef.current = (indexRef.current + 1) % Tables.length;
        }
        else if(rest===LoopCount-1) {
            setShowNow(false);
            // setTableName('PRODUCT');
        }
        blinkRef.current++;
    }, 800);

    const renderInTemplate = (winProps, index) => {
        const { x, y, w, h } = winProps.window;
        return (
            <Grow in={showNow} key={index} {...(showNow ? {timeout: winProps.delay+100} : {})}>
                <TextPaper sx={{ left: x, top: y, width: w, height: h, color: winProps.color }}>
                    {tableWord}
                </TextPaper>
            </Grow>
        );
    };

    return (
        <ConceptBox>
            <img src={SvgVzmConcept} alt="VZM Concept" width={ContainerWidth} style={{opacity: '0.7'}}/>
            <TableBox window={TablePaperWindow}>
                <Zoom in={showNow}>
                    <Box>
                        {tableName}
                        <Box style={{fontSize: '8pt', fontWeight:'normal', color:'#555'}}>
                            <div>-------</div>
                            <div>-----</div>
                            <div>---- -- ---</div>
                            <div>------ ---</div>
                        </Box>
                    </Box>
                </Zoom>
            </TableBox>
            {
                TemplatePaperWindows.map((item, index) => renderInTemplate(item, index))
            }
            {/* 
                TemplatePaperWindows.map((item, index) => {
                    return (
                        <Grow in={showNow} key={index} {...Box(showNow ? {timeout: item.delay} : {})}>
                            <TextPaper sx={{ left: item.window.x, top: item.window.y, width: item.window.w, height: item.window.h }}>
                                {tableWord}
                            </TextPaper>
                        </Grow>
                    )
                })
             */}
            {/* <Grow in={showNow}>
                <TextPaper sx={{ left: x, top: y, width: w, height: h }}>
                    {tableWord}
                </TextPaper>
            </Grow> */}
        </ConceptBox>
    );
}

/*


            {
                TemplatePaperWindows.map((item, index) => {
                    return (
                        <Grow key={index} in={showNow} {...Box(showNow ? {timeout: item.delay} : {})}>
                            <TemplateBox value={tableName} window={item.window} />
                        </Grow>
                    );
                })
            }


*/
