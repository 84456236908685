// vzm-hook.js
import { useCallback } from "react";
import { useRecoilValue } from "recoil";
import { languageAtom } from "../value/AppModel";


export function usePolyglot() {
    const language = useRecoilValue(languageAtom);
    return useCallback((labelObject, typeString)=>{
        if(!labelObject) return '';

        return labelObject[language.key] || labelObject.english;
    }, [language]);
};
